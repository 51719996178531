import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function AddBrand() {
  const [brandName, setBrandName] = useState('');
  const [brandId, setBrandId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showOkButton, setShowOkButton] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.brand) {
      const { BrandID, BrandName } = location.state.brand;
      setBrandId(BrandID);
      setBrandName(BrandName || '');
      setIsEditing(true);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${API_BASE_URL}/api/AdminGoldenTouch/add-brand`;
      const params = new URLSearchParams({
        brandID: brandId || 0,
        brandName: brandName
      });
      
      const response = await axios.get(`${url}?${params}`,{ withCredentials: true});
      
      setSuccessMessage(isEditing ? 'Brand successfully edited!' : 'Brand successfully added!');
      setShowOkButton(true);
    } catch (error) {
      console.error('Error:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleBack = () => {
    navigate('/admin/brands');
  };

  const handleOkClick = () => {
    navigate('/admin/brands');
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this brand?')) {
      try {
        const url = `${API_BASE_URL}/api/AdminGoldenTouch/delete-brand`;
        const params = new URLSearchParams({ brandID: brandId });
        
        const response = await axios.delete(`${url}?${params}`,{      withCredentials: true});
        
        setSuccessMessage('Brand successfully deleted!');
        setShowOkButton(true);
      } catch (error) {
        console.error('Error:', error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  return (
    <div className="add-category">
      <button onClick={handleBack} className="back-button">← Back to Brands</button>
      <br/> <br/> <br/>
      <h2>{isEditing ? 'Edit Brand' : 'Add New Brand'}</h2>
      {successMessage ? (
        <div className="success-message">
          {successMessage}
          {showOkButton && (
            <button onClick={handleOkClick} className="ok-button">OK</button>
          )}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="brandName">Brand Name:</label>
            <input
              type="text"
              id="brandName"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              required
            />
          </div>
          <div className="button-group">
            {isEditing && (
              <button 
                type="button" 
                onClick={handleDelete} 
                className="delete-button"
                style={{ backgroundColor: 'red', color: 'white' }}
              >
                Delete Brand
              </button>
            )}
            <button type="submit" className="update-button">
              {isEditing ? 'Update Brand' : 'Add Brand'}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default AddBrand;
