import React from 'react';
import { Navigate } from 'react-router-dom';

function AdminRoute({ user,children }) {



  if (!user || (user && !user.isAdmin)) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default AdminRoute;
