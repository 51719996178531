import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // In a real app, you'd validate credentials against a backend
  

      

    var data = JSON.stringify({
      "Username": username,
      "Password": password
    });

    var config = {
      method: 'post',
      url: API_BASE_URL + '/api/login/Login',
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data : data,
      withCredentials: true
      //withCredentials: true
      
    };

    axios(config)
    .then(function (response) {
      if(response.data.message==="ok")
      {
        const userData = { username, isAdmin: response.data.roles.roleId ==="1" ? 
          true:false , address: response.data.address, email:response.data.email};
        onLogin(userData);
        navigate('/');
      }
      else
      {
        alert('Invalid credentials');

      }
    })
    .catch(function (error) {
      alert('Invalid credentials');
    });

   
  };

  return (
    <div className="login">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;

