import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function SignUp() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    companyName: '', // Added companyName to formData
  });

  const [billingAddress, setBillingAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });

  const [isSameAsShipping, setIsSameAsShipping] = useState(true); // Checkbox state
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBillingChange = (e) => {
    setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = () => {
    setIsSameAsShipping(!isSameAsShipping);
    if (isSameAsShipping) {
      // If checked, clear billing address fields
      setBillingAddress({
        line1: '',
        line2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords don't match");
      return;
    }

    var data = JSON.stringify({
      "Username": formData.username,
      "Password": formData.password,
      "FirstName": formData.firstName,
      "LastName": formData.lastName,
      "Email": formData.email,
      "State": formData.state,
      "AddressLine1": formData.addressLine1,
      "AddressLine2": formData.addressLine2,
      "PhoneNumber": formData.phoneNumber,
      "PostalCode": formData.postalCode,
      "Country": formData.country,
      "isSameAsShipping": formData.isSameAsShipping,
      "BillingAddressLine1": isSameAsShipping ? formData.addressLine1 : billingAddress.line1,
      "BillingAddressLine2": isSameAsShipping ? formData.addressLine2 : billingAddress.line2,
      "BillingCity": isSameAsShipping ? formData.city : billingAddress.city,
      "BillingState": isSameAsShipping ? formData.state : billingAddress.state,
      "BillingZip": isSameAsShipping ? formData.postalCode : billingAddress.postalCode,
      "BillingCountry": isSameAsShipping ? formData.country : billingAddress.country,
      "companyName": formData.companyName // Added companyName to the data object
    });

    var config = {
      method: 'post',
      url: API_BASE_URL + '/api/login/signup',
      headers: {
        'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.message === "User registered successfully") {
          alert('Successful Registration');
          navigate('/login');
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        alert(error);
      });
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit} className="signup-form">
        {/* Existing fields */}
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input type="password" id="confirmPassword" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="companyName">Company Name:</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="firstName">First Name:</label>
          <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name:</label>
          <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input type="tel" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="addressLine1">Address Line 1:</label>
          <input type="text" id="addressLine1" name="addressLine1" value={formData.addressLine1} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="addressLine2">Address Line 2 (Optional):</label>
          <input type="text" id="addressLine2" name="addressLine2" value={formData.addressLine2} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="city">City:</label>
          <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="state">State:</label>
          <input type="text" id="state" name="state" value={formData.state} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="postalCode">Postal Code:</label>
          <input type="text" id="postalCode" name="postalCode" value={formData.postalCode} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="country">Country:</label>
          <input type="text" id="country" name="country" value={formData.country} onChange={handleChange} required />
        </div>
       

        {/* Checkbox for billing address */}
        <div className="form-group checkbox-group">
          <label>
            <input
              type="checkbox"
              checked={isSameAsShipping}
              onChange={handleCheckboxChange}
            />
            Billing address same as shipping address
          </label>
        </div>

        {/* Billing address fields */}
        {!isSameAsShipping && (
          <>
            <div className="form-group">
              <label htmlFor="billingAddressLine1">Billing Address Line 1:</label>
              <input
                type="text"
                id="billingAddressLine1"
                name="line1"
                value={billingAddress.line1}
                onChange={handleBillingChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="billingAddressLine2">Billing Address Line 2 (Optional):</label>
              <input
                type="text"
                id="billingAddressLine2"
                name="line2"
                value={billingAddress.line2}
                onChange={handleBillingChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="billingCity">Billing City:</label>
              <input
                type="text"
                id="billingCity"
                name="city"
                value={billingAddress.city}
                onChange={handleBillingChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="billingState">Billing State:</label>
              <input
                type="text"
                id="billingState"
                name="state"
                value={billingAddress.state}
                onChange={handleBillingChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="billingPostalCode">Billing Postal Code:</label>
              <input
                type="text"
                id="billingPostalCode"
                name="postalCode"
                value={billingAddress.postalCode}
                onChange={handleBillingChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="billingCountry">Billing Country:</label>
              <input
                type="text"
                id="billingCountry"
                name="country"
                value={billingAddress.country}
                onChange={handleBillingChange}
                required
              />
            </div>
          </>
        )}

        <button type="submit" className="signup-btn">Sign Up</button>
      </form>
    </div>
  );
}

export default SignUp;
