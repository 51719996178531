import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import '../css/SearchResultsPage.css';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function SearchResultsPage() {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get('q');

  useEffect(() => {
    if (searchQuery) {
      fetchSearchResults(searchQuery);
    }
  }, [searchQuery]);

  const fetchSearchResults = async (query) => {
    setLoading(true);
    try {
        const response = await axios.get(`${API_BASE_URL}/api/GoldenTouch/search-products?promptt=${query}`);
              setSearchResults(response.data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching search results. Please try again.');
      setLoading(false);
    }
  };

  const renderProductCards = () => {
    const productCards = searchResults.map((product) => (
      <Link to={`/product/${product.ProductID}`} key={product.ProductID} className="product-card">
        <div className="image-container">
          {product.ProductToVariations && 
           product.ProductToVariations.length > 0 && 
           product.ProductToVariations[0].ProductToVariationToImages &&
           product.ProductToVariations[0].ProductToVariationToImages.length > 0 ? (
            <img 
              src={`${API_BASE_URL}/${product.ProductToVariations[0].ProductToVariationToImages[0].ImagePath}`} 
              alt={product.Title}
            />
          ) : (
            <img src="https://via.placeholder.com/150" alt="Product placeholder" />
          )}
        </div>
        <h3 dangerouslySetInnerHTML={{ __html: product.Title }}></h3>
        <p className="price">${product.ProductToVariations[0].Price}</p>
      </Link>
    ));

    // Add empty placeholder cards to maintain grid structure
    const placeholderCount = 4 - (productCards.length % 4);
    for (let i = 0; i < placeholderCount && placeholderCount < 4; i++) {
      productCards.push(<div key={`placeholder-${i}`} className="product-card placeholder"></div>);
    }

    return productCards;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="search-results-page">
      <h2>Search Results for "{searchQuery}"</h2>
      {searchResults.length === 0 ? (
        <p>No products found matching your search.</p>
      ) : (
        <div className="product-grid">
          {renderProductCards()}
        </div>
      )}
    </div>
  );
}

export default SearchResultsPage;
