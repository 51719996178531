import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../index.css';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function Brands() {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    const config = {
      method: 'get',
      withCredentials: true,
      url: `${API_BASE_URL}/api/AdminGoldenTouch/get-brands`,
    };

    try {
      const response = await axios(config);
      setBrands(response.data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching brands. Please try again later.');
      setLoading(false);
    }
  };

  const handleRowClick = (brand) => {
    navigate('/admin/add-brand', { state: { brand } });
  };

  const handleAddNewBrand = () => {
    navigate('/admin/add-brand');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1>Brands</h1>
      <button onClick={handleAddNewBrand} className="add-new-button">Add New Brand</button>
      {brands.length === 0 ? (
        <p>No brands found.</p>
      ) : (
        <table className="categories-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {brands.map(brand => (
              <tr
                key={brand.BrandID}
                onClick={() => handleRowClick(brand)}
              >
                <td>{brand.BrandID}</td>
                <td>{brand.BrandName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Brands;
