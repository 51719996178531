import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';
import '../css/Header.css'

function Header({ user, onLogout }) {
  const { cart } = useContext(CartContext);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
    }
  };

  return (
    <header className="header">
      <nav className="nav-container">
        <div className="nav-left">
          {!user && (
            <form onSubmit={handleSearch} className="search-form">
              <button type="submit" className="search-button">&#128269;</button>
              <input
                type="text"
                placeholder="Search products..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </form>
          )}
          {user && user.isAdmin && (
            <>
              <Link to="/admin/products" className="nav-link">Products</Link>
              <Link to="/admin/categories" className="nav-link">Collections</Link>
              <Link to="/admin/sizes" className="nav-link">Sizes</Link>
              <Link to="/admin/brands" className="nav-link">Brands</Link>
              <Link to="/admin/colors" className="nav-link">Colors</Link>
              <Link to="/admin/casepacks" className="nav-link">Case Packs</Link>
              <Link to="/admin/orders" className="nav-link">Orders</Link>
            </>
          )}
        </div>
        <div className="nav-right">
        <Link to="/" className="nav-link home-link">Home</Link>
          {user ? (
            <button onClick={onLogout} className="nav-link">Logout</button>
          ) : (
            <>
              <Link to="/signup" className="nav-link">Sign Up</Link>
              <Link to="/login" className="nav-link">Login</Link>
            </>
          )}
          <Link to="/cart" className="nav-link cart-link">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="9" cy="21" r="1"></circle>
              <circle cx="20" cy="21" r="1"></circle>
              <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
            </svg>
            <span className="cart-count">{cart.reduce((sum, item) => sum + item.quantity, 0)}</span>
          </Link>
        </div>
      </nav>
    </header>
  );
}

export default Header;