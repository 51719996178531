import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../index.css';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function Sizes() {
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSizes();
  }, []);

  const fetchSizes = async () => {
    const config = {
      method: 'get',
      url: `${API_BASE_URL}/api/GoldenTouch/get-sizes`,
    };

    try {
      const response = await axios(config);
      setSizes(response.data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching sizes. Please try again later.');
      setLoading(false);
    }
  };

  const handleRowClick = (size) => {
    navigate('/admin/add-size', { state: { size } });
  };

  const handleAddNewSize = () => {
    navigate('/admin/add-size');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1>Sizes</h1>
      <button onClick={handleAddNewSize} className="add-new-button">Add New Size</button>
      {sizes.length === 0 ? (
        <p>No sizes found.</p>
      ) : (
        <table className="categories-table"> {/* Use the same class name as categories */}
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {sizes.map(size => (
              <tr
                key={size.SizeID}
                onClick={() => handleRowClick(size)}
              >
                <td>{size.SizeID}</td>
                <td>{size.SizeName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Sizes;
