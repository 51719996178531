import React, { useState, useContext, useEffect, useRef } from 'react';
import { CartContext } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import '../css/Checkout.css';

// Import API_BASE_URL correctly
const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function Checkout({ user }) {
  const { cart, clearCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [guestEmail, setGuestEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const errorRef = useRef(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSameAsShipping, setIsSameAsShipping] = useState(true); // New state for checkbox
  const [billingAddress, setBillingAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  });
  const [companyName, setCompanyName] = useState(''); // New state for company name

  // Use effect to populate form fields with user data when available
  useEffect(() => {
    
    if (user) {
      setFirstName(user.address.firstName || '');
      setLastName(user.address.lastName || '');
      setPhoneNumber(user.address.phoneNumber || '');
      setAddress1(user.address.addressLine1 || '');
      setAddress2(user.address.addressLine2 || '');
      setCity(user.address.city || '');
      setState(user.address.state || '');
      setZipCode(user.address.postalCode || '');
      setGuestEmail(user.email)
      setCompanyName(user.address.companyName)
    }
  }, [user]);

  const total = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear any previous error messages

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(guestEmail)) {
      setErrorMessage('Please enter a valid email address.');
      return; // Stop submission if validation fails
    }

    // //Validate reCAPTCHA
    // if (!captchaValue) {
    //   setErrorMessage('Please complete the reCAPTCHA.');
    //   return; // Stop submission if reCAPTCHA is not completed
    // }






    const orderData = {
      user: user ? user.username : 'Guest',
      firstName,
      lastName,
      email:  guestEmail,
      phoneNumber,
      companyName, // Include company name in order data
      ShippingAddress: {
        AddressLine1: address1,
        AddressLine2 :address2,
        city,
        state,
        postalCode: zipCode,
        UserID: user ? user.address.userID : null
      },
      BillingAddress: {
        AddressLine1: isSameAsShipping? address1 : billingAddress.line1,
        AddressLine2 : isSameAsShipping? address2: billingAddress.line2,
        city: isSameAsShipping? city:billingAddress.city,
        state:isSameAsShipping? state:billingAddress.state,
        postalCode: isSameAsShipping?  zipCode: billingAddress.zip,
        UserID: user ? user.address.userID : null
      },
      isSameAsShipping: isSameAsShipping,
      cart,
      total
    };

    try {
      const response = await fetch(API_BASE_URL + '/api/goldentouch/add-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        throw new Error('There was a problem processing your order. Please try again.');
      }

      const result = await response.json();

      clearCart();
      setOrderPlaced(true);
    } catch (error) {
      console.error('Error submitting order:', error);
      setErrorMessage(error.message);
      // Scroll to error message
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const handleOkClick = () => {
    navigate('/');
  };

  const handleCheckboxChange = () => {
    setIsSameAsShipping(!isSameAsShipping); // Toggle checkbox state
    if (isSameAsShipping) {
      // If checked, clear billing address fields
      setBillingAddress({
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: ''
      });
    }
  };

  if (orderPlaced) {
    return (
      <div className="order-placed-container">
        <h2>Your order has been placed!</h2>
        <button 
          onClick={handleOkClick}
          className="ok-button"
        >
          OK
        </button>
      </div>
    );
  }

  return (
    <div className="checkout-container">
      <h2>Checkout</h2>
      <div className="checkout-content">
        <form onSubmit={handleSubmit} className="checkout-form">
        <div className="form-group">
            <label htmlFor="companyName">Company Name:</label>
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)} // Handle company name input
            />
          </div>
          <div className="form-group">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
             //readOnly={!!user}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
             // readOnly={!!user}
            />
          </div>
         
            <div className="form-group">
              <label htmlFor="guestEmail">Email:</label>
              <input
                type="email"
                id="guestEmail"
                value={guestEmail}
                onChange={(e) => setGuestEmail(e.target.value)}
                required
              />
            </div>
          
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input
              type="tel" // Changed to 'tel' for better mobile keyboard
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))} // Allow only digits
              required
             // readOnly={!!user}
              pattern="\d*" // Allow only numeric input
            />
          </div>
          <div className="form-group">
            <label htmlFor="address1">Address Line 1:</label>
            <input
              type="text"
              id="address1"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              required
              //readOnly={!!user}
            />
          </div>
          <div className="form-group">
            <label htmlFor="address2">Address Line 2:</label>
            <input
              type="text"
              id="address2"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              //readOnly={!!user}
            />
          </div>
          <div className="form-group">
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
              //readOnly={!!user}
            />
          </div>
          <div className="form-group">
            <label htmlFor="state">State:</label>
            <input
              type="text"
              id="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
              //readOnly={!!user}
            />
          </div>
          <div className="form-group">
            <label htmlFor="zipCode">ZIP Code:</label>
            <input
              type="text"
              id="zipCode"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              required
              //readOnly={!!user}
            />
          </div>
           {/* <ReCAPTCHA
            sitekey="6LcTuGAqAAAAAI0LuZ7id16hn6GaFOJxORJIhvl6" // Replace with your site key
            onChange={(value) => setCaptchaValue(value)}
            size="invisible"
          />  */}
          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={isSameAsShipping}
                onChange={handleCheckboxChange}
              />
              Billing address same as shipping address
            </label>
          </div>

          {/* Conditionally render billing address fields */}
          {!isSameAsShipping && (
            <>
              <div className="form-group">
                <label htmlFor="billingAddress1">Billing Address Line 1:</label>
                <input
                  type="text"
                  id="billingAddress1"
                  value={billingAddress.line1}
                  onChange={(e) => setBillingAddress({ ...billingAddress, line1: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="billingAddress2">Billing Address Line 2:</label>
                <input
                  type="text"
                  id="billingAddress2"
                  value={billingAddress.line2}
                  onChange={(e) => setBillingAddress({ ...billingAddress, line2: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="billingCity">Billing City:</label>
                <input
                  type="text"
                  id="billingCity"
                  value={billingAddress.city}
                  onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="billingState">Billing State:</label>
                <input
                  type="text"
                  id="billingState"
                  value={billingAddress.state}
                  onChange={(e) => setBillingAddress({ ...billingAddress, state: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="billingZip">Billing ZIP Code:</label>
                <input
                  type="text"
                  id="billingZip"
                  value={billingAddress.zip}
                  onChange={(e) => setBillingAddress({ ...billingAddress, zip: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="billingCountry">Billing Country:</label>
                <input
                  type="text"
                  id="billingCountry"
                  value={billingAddress.country}
                  onChange={(e) => setBillingAddress({ ...billingAddress, country: e.target.value })}
                  required
                />
              </div>
            </>
          )}

          

          <button type="submit" className="place-order-btn">Place Order</button>
          {errorMessage && (
            <div ref={errorRef} className="error-message">
              {errorMessage}
            </div>
          )}
        </form>
        <div className="order-summary">
          <h3>Order Summary</h3>
         
          {cart.map((item) => (
            <div key={item.id} className="order-item">
              <span className="item-name">{item.title}</span>
              <span className="item-quantity">x {item.quantity}</span>
              <span className="item-price">${(item.price * item.quantity).toFixed(2)}</span>
            </div>
          ))}
          <div className="order-total">
            <strong>Total:</strong> ${total.toFixed(2)}
          </div>
          <br/>
          <br/>
          <div >
            <strong>Payment Method: Cash On Delivery (COD)</strong> {/* Added payment method display */}
          </div>
        </div>
       
        
       
      </div>
    </div>
  );
}

export default Checkout;
