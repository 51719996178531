import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../index.css';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function CasePacks() {
  const [casePacks, setCasePacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCasePacks();
  }, []);

  const fetchCasePacks = async () => {
    const config = {
      method: 'get',
      url: `${API_BASE_URL}/api/AdminGoldenTouch/get-case-packs`,
      withCredentials: true,
    };

    try {
      const response = await axios(config);
      setCasePacks(response.data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching case packs. Please try again later.');
      setLoading(false);
    }
  };

  const handleRowClick = (casePack) => {
    navigate('/admin/add-casepack', { state: { casePack } });
  };

  const handleAddNewCasePack = () => {
    navigate('/admin/add-casepack');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1>Case Packs</h1>
      <button onClick={handleAddNewCasePack} className="add-new-button">Add New Case Pack</button>
      {casePacks.length === 0 ? (
        <p>No case packs found.</p>
      ) : (
        <table className="categories-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {casePacks.map(casePack => (
              <tr
                key={casePack.CasePackID}
                onClick={() => handleRowClick(casePack)}
              >
                <td>{casePack.CasePackID}</td>
                <td>{casePack.CasePackName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default CasePacks;
