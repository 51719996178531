import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../index.css'; // Import the CSS file

// Add this line near the top of your file
const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function Categories() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const config = {
      method: 'get',
      url: `${API_BASE_URL}/api/AdminGoldenTouch/get-categories`,
      withCredentials: true,
    };

    try {
      const response = await axios(config);
      setCategories(response.data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching Collection. Please try again later.');
      setLoading(false);
    }
  };

  const handleRowClick = (category) => {
    navigate('/admin/add-category', { state: { category } });
  };

  const handleAddNewCategory = () => {
    navigate('/admin/add-category');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1>Collections</h1>
      <button onClick={handleAddNewCategory} className="add-new-button">Add New Collection</button>
      {categories.length === 0 ? (
        <p>No Collection found.</p>
      ) : (
        <table className="categories-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {categories.map(category => (
              <tr
                key={category.CategoryID}
                onClick={() => handleRowClick(category)}
              >
                <td>{category.CategoryID}</td>
                <td>{category.CategoryName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Categories;
