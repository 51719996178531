import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../css/CategoryMenu.css';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function CategoryMenu() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchCategories();
    console.log(window.innerWidth);
    if(window.innerWidth <= 768)
    {
      setIsOpen(false);
    }
    else
    {
      setIsOpen(true);
      
      
    }
    

  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/GoldenTouch/get-categories-home`);
      setCategories(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching categories:', err);
      setError('Failed to fetch categories');
      setLoading(false);
    }
  };

  const toggleMenu = () => {
    if(window.innerWidth <= 768)
    {
      setIsOpen(!isOpen);

    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <nav className="category-menu">
      <button id="menu-tog" className="menu-toggle" onClick={toggleMenu}>
        Categories {isOpen ? '▲' : '▼'}
      </button>
      {categories.length === 0 ? (
        <p>No categories available</p>
      ) : (
        <ul className={`menu-list ${isOpen ? 'open' : ''}`}>
          {categories.map((category) => (
            <li key={category.CategoryID}>
              <Link 
                to={`/category/${category.CategoryID}`} 
                onClick={() => toggleMenu()}
              >
                {category.CategoryName}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
}

export default CategoryMenu;
