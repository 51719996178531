import React from 'react';
import { Link } from 'react-router-dom';


const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

const categories = [
  { name: 'Men', image: API_BASE_URL + '/UploadedFiles/MenCard.jpg',ID: 2 },
  { name: 'Women', image: API_BASE_URL + '/UploadedFiles/WomensCard.jpg',ID:4 },
  { name: 'Kids', image: API_BASE_URL + '/UploadedFiles/KidsCard.jpg', ID:3 },
  { name: 'Socks', image: API_BASE_URL + '/UploadedFiles/SocksCard.jpg' , ID:6 },
  { name: 'Slightly Imperfect', image: API_BASE_URL + '/UploadedFiles/sl.jpg', ID:5  },
  { name: 'First Quality', image: API_BASE_URL + '/UploadedFiles/FQ.jpg', ID:7  },
];

function CategoryCards() {
  return (
    <div className="category-cards">
      {categories.map((category) => (
        <Link to={`/category/${category.ID}`} key={category.ID} className="category-card">
          <div className="category-card-inner" style={{ backgroundImage: `url(${category.image})` }}>
            <h3>{category.name}</h3>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default CategoryCards;
