import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Switch } from 'react-router-dom';
import { CartProvider } from './contexts/CartContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Products from './pages/AdminProductsPage';
import AddCategory from './pages/AddCategory';
import SignUp from './pages/SignUp'; // Import the new SignUp component
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import ProductDetails from './pages/ProductDetailsPage';
import Login from './pages/Login';
import Categories from './pages/Categories';
import Sizes from './pages/Sizes';
import AddSize from './pages/AddSize';
import Brands from './pages/Brands';
import AddBrand from './pages/AddBrand';
import Colors from './pages/Colors';
import AddColor from './pages/AddColor';
import EditProductPage from './pages/EditProductPage';
import CategoryProductsPage from './pages/CategoryProductsPage';
import PrivateRoute from './components/PrivateRoute';
// Import the new AdminOrdersPage
import AdminOrdersPage from './pages/AdminOrdersPage';
import CasePacks from './pages/CasePacks';
import AddCasePack from './pages/AddCasePack';
import SearchResultsPage from './pages/SearchResultsPage';
import AdminRoute from './components/AdminRoute';
import OrderDetails from './pages/AdminOrderDetails';

function App() {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  const handleLogin = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    setUser(null);
  };

  return (
    <CartProvider>
      <Router>
        <div className="App">
          <Header user={user} onLogout={handleLogout} />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/admin/Categories" element={<AdminRoute user={user}><Categories /></AdminRoute>} />
              <Route path="/admin/products" element={<AdminRoute user={user}><Products /></AdminRoute>} />
              <Route path="/admin/casepacks" element={<AdminRoute user={user}><CasePacks /></AdminRoute>} />
              <Route path="/admin/add-casepack" element={<AdminRoute user={user}><AddCasePack /></AdminRoute>} />


              <Route 
                path="/admin/add-category" 
                element={<AdminRoute user={user}><AddCategory /> </AdminRoute>} 
              />
              <Route 
                  element={<AdminRoute user={user}> <EditProductPage /></AdminRoute>} 

              path="/admin/products/edit/:id" />

              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout" element={<Checkout user={user} />} />
              <Route path="/product/:productId" element={<ProductDetails />} />
              <Route path="/admin/sizes" element={<AdminRoute user={user}><Sizes /></AdminRoute>} />
              <Route path="/admin/add-size" element={<AdminRoute user={user}><AddSize /></AdminRoute>} />
              <Route path="/admin/brands" element={<AdminRoute user={user}><Brands /></AdminRoute>} />
              <Route path="/admin/add-brand" element={<AdminRoute user={user}><AddBrand /></AdminRoute>} />
              <Route path="/admin/colors" element={<AdminRoute user={user}><Colors /></AdminRoute>} />
              <Route path="/admin/add-color" element={<AdminRoute user={user}><AddColor /></AdminRoute>} />
              <Route path="/search" element={<SearchResultsPage />} />

              <Route path="/category/:categoryId" element={
                <PrivateRoute>
                  <CategoryProductsPage />
                </PrivateRoute>
              } />
              {/* Add the new AdminOrdersPage route */}
              <Route 
                path="/admin/orders" 
                element={<AdminRoute user={user}><AdminOrdersPage /></AdminRoute> } 
              />
              <Route path="/admin/orders/:orderId" element={<OrderDetails />} />
              <Route path="/admin/casepacks" element={<AdminRoute user={user}><CasePacks /></AdminRoute>} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
