import React, { useState, useEffect } from 'react';
import CategoryMenu from '../components/CategoryMenu';
import CategoryCards from '../components/CategoryCards';
import FeaturedProducts from '../components/FeaturedProducts';
import mainImage from '../assets/gd.jpg'; // Adjust the path if necessary
import axios from 'axios';
import '../css/Home.css'; // Add this line to import the CSS

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function Home() {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  useEffect(() => {
    fetchFeaturedProducts();
  }, []);

  const fetchFeaturedProducts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/GoldenTouch/get-product-by-feature`);
      setFeaturedProducts(response.data);
    } catch (error) {
      console.error('Error fetching featured products:', error);
    }
  };

  return (
    <div className="home">
      <img 
        src={mainImage} 
        alt="Golden Denim" 
        className='main-image'
        style={{
          "max-width": '600px',
          height: '80%',
          //display: 'block',
          margin: '0 auto',
         
         
        }}
      />

      <div className="home-content">
        <CategoryMenu />
        <div className="main-content">
          <div className="welcome-section">
            <h1>Welcome to Our Store</h1>
          </div>
          <CategoryCards />
          <div className="featured-products-section">
            <h2>Featured Products</h2>
            <FeaturedProducts products={featuredProducts} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
