import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <h3>GOLDEN TOUCH MORRIS</h3>
        <p>Cell: 917-916-4202</p>
        <p>Fax: 718-998-3362</p>
        <p>Email: <a href="mailto:Goldentouchofnyc@gmail.com">Goldentouchofnyc@gmail.com</a></p>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Golden Touch. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
