import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

// Styled components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  color: #333;
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
`;

const OrderInfo = styled.div`
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const Th = styled.th`
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
`;

const Input = styled.input`
  width: 50px;
  padding: 5px;
`;

const Button = styled.button`
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #45a049;
  }
`;

const RemoveButton = styled(Button)`
  background-color: #f44336;

  &:hover {
    background-color: #d32f2f;
  }
`;

const Total = styled.p`
  font-size: 1.2em;
  font-weight: bold;
  text-align: right;
`;

const EditButton = styled(Button)`
  background-color: #2196F3;
  padding: 5px 10px;
  font-size: 14px;

  &:hover {
    background-color: #1976D2;
  }
`;

const DisabledInput = styled(Input)`
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  color: #666;
`;

const EditingInput = styled(Input)`
  background-color: #ffebee;
  border: 1px solid #f44336;
  color: #d32f2f;
`;

const StatusSelect = styled.select`
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const StatusDisplay = styled.span`
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  color: #666;
`;

const AdminOrderDetails = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);
  const [editingLineId, setEditingLineId] = useState(null);
  const [editingStatus, setEditingStatus] = useState(false);

  const getOrder = async (id) => {
    if (!id) {
      throw new Error('Order ID is missing');
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/api/AdminGoldenTouch/get-order?orderID=${id}`,{      withCredentials: true});
      return response.data;
    } catch (error) {
      console.error('Error fetching order:', error);
      throw error;
    }
  };

  const updateOrder = async (updatedOrder) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/AdminGoldenTouch/update-order`, updatedOrder,{withCredentials:true});
      alert("Order Updated")
      return response.data;
    } catch (error) {
      console.error('Error updating order:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  useEffect(() => {
    const fetchOrder = async () => {
      if (!orderId) {
        setError('No order ID provided');
        return;
      }
      try {
        const fetchedOrder = await getOrder(orderId);
        setOrder(fetchedOrder);
      } catch (error) {
        setError(`Failed to fetch order: ${error.message}`);
      }
    };

    fetchOrder();
  }, [orderId]);

  const handleRemoveLine = (lineId) => {
    if (window.confirm('Are you sure you want to remove this item?')) {
      // Remove line from order
      setOrder(prevOrder => ({
        ...prevOrder,
        OrderDetails: prevOrder.OrderDetails.filter(line => line.OrderDetailID !== lineId)
      }));
    }
  };

  const handleEditQuantity = (lineId) => {
    setEditingLineId(lineId);
  };

  const handleQuantityChange = (lineId, newQty) => {
    setOrder(prevOrder => ({
      ...prevOrder,
      OrderDetails: prevOrder.OrderDetails.map(line =>
        line.OrderDetailID === lineId ? { ...line, Quantity: newQty } : line
      )
    }));
  };

  const handleSaveQuantity = () => {
    setEditingLineId(null);
    // Here you might want to call an API to update the quantity on the server
  };

  const handleUpdateOrder = () => {
    // Update order in the backend
    updateOrder(order).then(updatedOrder => setOrder(updatedOrder));
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setOrder(prevOrder => ({
      ...prevOrder,
      Status: newStatus
    }));
    setEditingStatus(false);
    // Here you might want to call an API to update the status on the server
  };

  const toggleStatusEdit = () => {
    setEditingStatus(!editingStatus);
  };

  if (error) return <Container>Error: {error}</Container>;
  if (!order) return <Container>Loading...</Container>;

  if (!order.OrderDetails) {
    console.error('Order details are missing:', order);
    return <Container>Error: Order details are missing</Container>;
  }

  const total = order.OrderDetails.reduce((sum, line) => sum + line.Price * line.Quantity, 0);

  return (
    <Container>
      <Title>Order Details</Title>
      <OrderInfo>
        <p><b>Order Number:</b> {order.OrderID}</p>
        <p>
          <b>Status: </b>
          {editingStatus ? (
            <StatusSelect value={order.Status} onChange={handleStatusChange}>
              <option value="Ordered">Ordered</option>
              <option value="Shipped">Shipped</option>
              <option value="Cancelled">Cancelled</option>
            </StatusSelect>
          ) : (
            <StatusDisplay>{order.Status}</StatusDisplay>
          )}
          <EditButton onClick={toggleStatusEdit}>Edit</EditButton>
        </p>
        <p><b>Full Name: </b>{order.ShippingName}</p>
        <p><b>Company Name: </b>{order.CompanyName}</p>
        <p><b>Address: </b>{order.ShippingAddressLine1}{order.ShippingAddressLine2 ? `, ${order.ShippingAddressLine2}` : ''},{order.ShippingCity},{order.ShippingState},{order.ShippingZip}</p>
        <p><b>Billing Address: </b>{order.BillingAddressLine1}{order.BillingAddressLine2 ? `, ${order.BillingAddressLine2}` : ''},{order.BillingCity},{order.BillingState},{order.BillingZip}</p>
      </OrderInfo>
      
      <Table>
        <thead>
          <tr>
            <Th>Product</Th>
            <Th>Size</Th>
            <Th>Color</Th>
            <Th>Casepack</Th>
            <Th>Quantity</Th>
            <Th>Edit Quantity</Th>
            <Th>Price</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {order.OrderDetails.map(line => (
            <tr key={line.OrderDetailID}>
              <Td>{line.ProductToVariations.SkuNumber}</Td>
              <Td>{line.ProductToVariations.Size.SizeName || 'N/A'}</Td>
              <Td>{line.ProductToVariations.Color.ColorName || 'N/A'}</Td>
              <Td>{line.ProductToVariations.CasePack.CasePackName || 'N/A'}</Td>
              <Td>
                {editingLineId === line.OrderDetailID ? (
                  <EditingInput
                    type="number"
                    value={line.Quantity}
                    onChange={(e) => handleQuantityChange(line.OrderDetailID, parseInt(e.target.value))}
                  />
                ) : (
                  <DisabledInput
                    type="number"
                    value={line.Quantity}
                    disabled
                  />
                )}
              </Td>
              <Td>
                {editingLineId === line.OrderDetailID ? (
                  <EditButton onClick={handleSaveQuantity}>Save</EditButton>
                ) : (
                  <EditButton onClick={() => handleEditQuantity(line.OrderDetailID)}>Edit</EditButton>
                )}
              </Td>
              <Td>${line.Price}</Td>
              <Td>
                <RemoveButton onClick={() => handleRemoveLine(line.OrderDetailID)}>Remove</RemoveButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
      
      <TotalAndUpdateContainer>
        <Total>Total: ${total.toFixed(2)}</Total>
        <Button onClick={handleUpdateOrder}>Update Order</Button>
      </TotalAndUpdateContainer>
    </Container>
  );
};

const TotalAndUpdateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export default AdminOrderDetails;
