import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../index.css'; // Import the CSS file

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function AdminOrdersPage() {
  const [allOrders, setAllOrders] = useState([]);
  const [displayedOrders, setDisplayedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50); // Default page size
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    updateDisplayedOrders();
  }, [allOrders, currentPage, pageSize]);

  const fetchOrders = async () => {
    const config = {
      method: 'get',
      url: `${API_BASE_URL}/api/AdminGoldenTouch/get-orders`,
      withCredentials: true
    };

    try {
      setLoading(true);
      const response = await axios(config);
      if (Array.isArray(response.data)) {
        setAllOrders(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
        setError('Unexpected data format received from the server.');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError('Failed to fetch orders. Please try again later.');
      setLoading(false);
    }
  };

  const updateDisplayedOrders = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedOrders(allOrders.slice(startIndex, endIndex));
  };

  const handleRowClick = (orderId) => {
    navigate(`/admin/orders/${orderId}`);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const totalPages = Math.ceil(allOrders.length / pageSize);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="admin-orders-page">
      <h1 className="page-title">Admin Orders</h1>
      <div className="table-controls">
        <div className="page-size-selector">
          <label htmlFor="pageSize">Items per page:</label>
          <select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div className="table-container">
        <table className="orders-table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Customer Name</th>
              <th>Order Date</th>
              <th>Total Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {displayedOrders.map((order) => (
              <tr key={order.OrderID} onClick={() => handleRowClick(order.OrderID)} className="order-row">
                <td>{order.OrderID}</td>
                <td>{order.ShippingName}</td>
                <td>{new Date(order.OrderDate).toLocaleString('en-US', { timeZone: 'America/New_York' })}</td>
                <td>${order.TotalAmount.toFixed(2)}</td>
                <td>
                  <span className={`status-badge status-${order.Status.toLowerCase()}`}>
                    {order.Status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <div className="pagination">
          <button 
            onClick={() => handlePageChange(currentPage - 1)} 
            disabled={currentPage === 1}
            className="pagination-button"
          >
            &lt; Previous
          </button>
          <span className="page-info">Page {currentPage} of {totalPages}</span>
          <button 
            onClick={() => handlePageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            Next &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminOrdersPage;
