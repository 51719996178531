import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../index.css'; // Import the CSS file
import { MdVisibility } from 'react-icons/md'; // Import the eye icon

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function AdminProductsPage() {
  const [allProducts, setAllProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50); // Default page size
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(''); // New state for search query

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    updateDisplayedProducts();
  }, [allProducts, currentPage, pageSize, searchQuery]); // Add searchQuery to dependencies

  const fetchProducts = async () => {
    const config = {
      method: 'get',
      url: `${API_BASE_URL}/api/AdminGoldenTouch/get-products`,
      withCredentials: true,
    };

    try {
      setLoading(true);
      const response = await axios(config);
      if (Array.isArray(response.data)) {
        setAllProducts(response.data);
      } else {
        console.error('Unexpected API response format:', response.data);
        setError('Unexpected data format received from the server.');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to fetch products. Please try again later.');
      setLoading(false);
    }
  };

  const updateDisplayedProducts = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setDisplayedProducts(allProducts.slice(startIndex, endIndex));
  };

  const handleRowClick = (productId) => {
    navigate(`/admin/products/edit/${productId}`);
  };

  // New function to handle preview
  const handlePreviewClick = (productId) => {
    window.open(`/product/${productId}`, '_blank'); // Open in new tab
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const filteredProducts = allProducts.filter(product => 
    product.Title.toLowerCase().includes(searchQuery.toLowerCase()) || 
    product.ProductToVariations.some(variation => 
        variation.SkuNumber.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredProducts.length / pageSize); // Update totalPages calculation

  const handleAddNewProduct = () => {
    navigate('/admin/products/edit/0');
  };

  useEffect(() => {
    setCurrentPage(1); // Reset to first page when search query changes
  }, [searchQuery]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="admin-products-page">
      <h1 className="page-title">Admin Products</h1>
      <div className="table-controls">
        <div className="page-size-selector">
          <label htmlFor="pageSize">Items per page:</label>
          <select id="pageSize" value={pageSize} onChange={handlePageSizeChange}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <input 
          type="text" 
          placeholder="Search by title or sku..." 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query
          className="search-bar"
          style={{ margin: '0 auto', display: 'block' }} // Center the search bar
        />
        <button onClick={handleAddNewProduct} className="add-product-btn">Add New Product</button>
      </div>
      <div className="table-container">
        <table className="products-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Image</th>
              <th>Title</th>
              <th>Category</th>
              <th>Brand</th>
              <th>Date Created</th>
              <th>Is Featured</th>
              <th>Preview</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product) => ( // Use filtered products for display
              <tr key={product.ProductID} onClick={() => handleRowClick(product.ProductID)} className="product-row">
                <td>{product.ProductID}</td>
                <td>
                  <img 
                    src={API_BASE_URL+"/"+product.ProductToVariations[0]?.ProductToVariationToImages[0]?.ImagePath}
                    //alt={product.Title} 
                    className="product-image"
                  />
                </td>
                <td>{product.Title}</td>
                <td>{product.Category?.CategoryName}</td>
                <td>{product.Brand?.BrandName}</td>
                <td>{new Date(product.DateCreated).toLocaleDateString()}</td>
                <td>{product.IsFeatured ? 'Yes' : 'No'}</td>
                <td>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      handlePreviewClick(product.ProductID);
                    }} 
                    className="preview-product-btn"
                  >
                    <MdVisibility style={{ fontSize: '24px', color: '#007bff', transition: 'color 0.3s' }} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <div className="pagination">
          <button 
            onClick={() => handlePageChange(currentPage - 1)} 
            disabled={currentPage === 1}
            className="pagination-button"
          >
            &lt; Previous
          </button>
          <span className="page-info">Page {currentPage} of {totalPages}</span>
          <button 
            onClick={() => handlePageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            Next &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminProductsPage;
