import React from 'react';
import { Link } from 'react-router-dom';
import '../css/FeaturedProducts.css';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function FeaturedProducts({ products }) {
  return (
    <div className="featured-products">
      {products.map((product) => (
        <Link to={`/product/${product.ProductID}`} key={product.ProductID} className="featured-product-card">
          <div className="image-container">
            {product.ProductToVariations && 
             product.ProductToVariations.length > 0 && 
             product.ProductToVariations[0].ProductToVariationToImages &&
             product.ProductToVariations[0].ProductToVariationToImages.length > 0 ? (
              <img 
                src={`${API_BASE_URL}/${product.ProductToVariations[0].ProductToVariationToImages[0].ImagePath}`} 
                alt={product.Title}
              />
            ) : (
              <img src="https://via.placeholder.com/150" alt="Product placeholder" />
            )}
          </div>
          <h3>{product.Title}</h3>
          <p className="price">${product.ProductToVariations[0].Price}</p>
        </Link>
      ))}
    </div>
  );
}

export default FeaturedProducts;
