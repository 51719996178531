import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../index.css';

const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

function Colors() {
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchColors();
  }, []);

  const fetchColors = async () => {
    const config = {
      method: 'get',
      url: `${API_BASE_URL}/api/GoldenTouch/get-colors`,
    };

    try {
      const response = await axios(config);
      setColors(response.data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching colors. Please try again later.');
      setLoading(false);
    }
  };

  const handleRowClick = (color) => {
    navigate('/admin/add-color', { state: { color } });
  };

  const handleAddNewColor = () => {
    navigate('/admin/add-color');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1>Colors</h1>
      <button onClick={handleAddNewColor} className="add-new-button">Add New Color</button>
      {colors.length === 0 ? (
        <p>No colors found.</p>
      ) : (
        <table className="categories-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {colors.map(color => (
              <tr
                key={color.ColorID}
                onClick={() => handleRowClick(color)}
              >
                <td>{color.ColorID}</td>
                <td>{color.ColorName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Colors;
